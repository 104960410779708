//treeviewcomponet sytle starts

.e-sidebar {
    // max-width: 280px !important;
    border: 0px;

    &.e-left {
        border: 0px;
    }

    .profileDiv .e-btn-img-sm {
        background-color: transparent !important;
        border: 0 !important;

        &.active {
            border: 0.0625em solid #004d8e !important;
            background-color: rgba(0, 0, 0, 0.3) !important;
        }
    }

    &.e-open {
        overflow: visible;
    }

    &.e-close {
        overflow: hidden;
    }

    &__left {
        width: 65px;
        min-width: 65px;
        max-width: 65px;
        height: 100%;
        display: flex;
        flex-direction: column;
        // box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
        // z-index: 101;
        position: relative;
        // background: #142656;
        background-color: #0f1634;
        z-index: 1010;
    }

    &__right {
        height: 100%;
        background: #142656;
        width: 195px;
        box-shadow: -5px 0px 7px -2px rgba(0, 0, 0, 0.25) !important;
        overflow: visible;

        &__chevrons__right {
            &__div {
                position: absolute;
                right: -0.375rem;
                top: 66px;
                z-index: 1011;

            }

            &__btn.e-btn.e-icon-btn {
                width: 1.25rem !important;
                height: 1.25rem !important;
                min-width: 1.25rem !important;
                min-height: 1.25rem !important;
                // background-color: #f55c06 !important;
                background-color: #5b39f0 !important;
                color: #fff;
                border: 1px solid #fff;
                border-radius: 50% !important;
                box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);

                & .e-btn-icon {
                    font-size: 0.8125rem;
                    color: #fff !important;
                    margin-top: -0.25rem;
                }
            }
        }

        &__col {
            overflow: visible;
            position: relative;

            // width: 100% !important;
            &:empty {
                display: none;
            }

            &__portals {
                // overflow: visible;
                display: flex;
                flex-direction: column;
                background-color: #142656;
                height: 100%;

            }
        }

        &__section {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.08);

            .secondary-text-sm,
            .header-text-sm {
                line-height: normal;
            }

            & .e-accordion {
                background-color: transparent;
                border: 0px;

                & .e-acrdn-item {
                    background-color: transparent !important;
                    border: 0px !important;

                    &>.e-acrdn-header {
                        border: 0px !important;
                        // padding: 0 .3125rem 0 1.75rem;
                        padding: 0 0rem 0 1.75rem;
                        border-bottom: 0px solid #004d8e !important;
                        background-color: transparent !important;

                        & .e-acrdn-header-content {
                            color: #fff !important;
                            font-weight: 500;
                            text-transform: uppercase;
                            width: 100%;
                            font-size: 0.75rem;
                        }

                        &>.e-toggle-icon {
                            left: 0.3125rem;

                            & .e-icons.e-tgl-collapse-icon {
                                color: #fff !important;
                                font-size: 0.75rem;
                            }
                        }

                        &:hover {
                            background-color: #0f1634 !important;

                            & .e-acrdn-header-content {
                                color: #fff !important;
                            }

                            &>.e-toggle-icon .e-icons.e-tgl-collapse-icon {
                                color: #fff !important;

                            }
                        }
                    }

                    &>.e-acrdn-panel .e-acrdn-content {
                        padding: 0;
                    }
                }
            }

            & .e-treeview {
                padding: 0rem;
                overflow: auto !important;
                margin: 0 0rem;
                height: 100%;

                .e-list-item {

                    & .e-text-content {
                        display: flex;
                        align-items: center;
                        padding: 0.3125rem 0;

                        & .e-list-text {
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin: 0 !important;
                            padding: 0 0.25rem 0 0.5rem;
                            display: flex;
                            align-items: center;
                            height: 48px;
                        }

                        & .e-list-icon {
                            color: #fff;
                            display: inline-block !important;
                            font-size: 0.875rem;
                            vertical-align: middle;
                            height: auto;
                            margin: 0 0 0 1.5rem;
                            width: auto;
                        }
                    }
                }


                & .e-sidebar__right__treeview__actionBtn.e-btn {
                    background-color: transparent;
                    border-color: transparent;

                    & .e-btn-icon {
                        color: #fff;
                    }
                }
            }

        }

        &__tabview.e-tab {
            // background-color: rgba(0, 0, 0, 0.08);
            height: 100% !important;
            display: flex;
            flex-direction: column;

            & .e-tab-header {
                padding: 0 0.25rem;
                margin: 0.5rem;
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 8px;
                width: auto !important;
                min-height: unset;
                height: 40px !important;
                min-height: 40px !important;
                max-height: 40px !important;
                display: flex;
                align-items: center;

                &::before {
                    display: none;
                }

                & .e-toolbar-items {
                    min-height: unset;

                    & .e-toolbar-item {
                        height: auto;
                        min-height: unset;
                        width: 100%;
                        margin-right: 0px !important;

                        &.e-active {
                            & .e-tab-wrap {
                                border: unset;
                                height: auto;
                                background-color: rgba(255, 255, 255, 0.2);
                                border-radius: 8px;


                                & .e-tab-text {
                                    color: #fff;
                                    font-weight: 500;
                                }

                                & .e-text-wrap {
                                    height: auto;
                                    min-height: unset;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }

                        & .e-tab-wrap {
                            border: unset;
                            height: auto;
                            // padding: 0.25rem 0.5rem 0.5rem;
                            text-align: center;

                            & .e-tab-text {
                                color: #fff;
                                text-transform: uppercase;
                                font-size: 0.75rem;
                            }

                            & .e-text-wrap {
                                height: auto;
                                min-height: unset;
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }

            & .e-content {
                height: 100% !important;
                overflow: hidden;

                // &::-webkit-scrollbar,
                // &::-webkit-scrollbar {
                //     display: none;
                // }

                & .e-item,
                & .e-item>div,
                & .e-item>div>div,
                & .e-item>div>div>div {
                    height: 100%;
                }

                & .e-item>div>div>div {
                    display: flex;
                    flex-direction: column;

                    & .e-sidebar__right__treeview {
                        height: 100%;
                        overflow: auto !important;

                        &::-webkit-scrollbar {
                            width: 0px;
                            height: 0px;
                            border-radius: 4px;
                        }

                        &::-webkit-scrollbar-button {
                            display: none;
                        }

                        &::-webkit-scrollbar-corner {
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 9px;
                            background-color: #bbb;
                        }

                        &:hover::-webkit-scrollbar-thumb {
                            background-color: #999;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        &__treeview {

            &.e-treeview .e-ul {
                height: auto;
                overflow: visible;
                padding: 0;

                & .e-list-item {

                    &>.e-text-content {
                        height: 48px;
                        padding: 0;

                        & .e-list-text,
                        & .e-icon-collapsible,
                        & .e-icon-expandable {
                            color: #fff;
                            order: 0 !important;
                        }

                        & .e-icon-collapsible,
                        & .e-icon-expandable {
                            margin: 0 !important;
                            position: absolute;
                        }
                    }

                    &>.e-fullrow {
                        border-radius: 0.25rem;
                        height: 48px;
                    }

                    & .e-list-parent.e-ul {
                        padding-left: 0.75rem;
                    }

                    &.e-hover {
                        &>.e-fullrow {
                            background-color: #0f1634;
                            border: 0px !important;
                            // left: 0.375rem;
                        }

                    }

                    &.e-active {
                        &>.e-fullrow {
                            background-color: #0f1634;
                            border: 0px !important;
                            // left: 0.375rem;
                            border-left: 3px solid transparent !important;
                        }

                        &>.e-text-content {

                            & .e-list-text,
                            & .e-icon-collapsible {
                                color: #fff !important;
                            }
                        }

                        &.e-node-focus {
                            &>.e-fullrow {
                                border-left: 3px solid transparent !important;
                            }
                        }

                    }

                    &.user-selected-tree-item {
                        &>.e-fullrow {
                            background-color: #0f1634;
                            border: 0px !important;
                            // left: 0.375rem;
                            border-left: 3px solid #58bff6 !important;
                        }

                        &>.e-text-content {

                            & .e-list-text,
                            & .e-icon-collapsible {
                                color: #fff !important;
                            }
                        }

                        &.e-node-focus {
                            &>.e-fullrow {
                                background-color: #0f1634;
                                border: 0px !important;
                                border-left: 3px solid #58bff6 !important;
                            }
                        }
                    }

                    &.e-node-focus {
                        &>.e-fullrow {
                            background-color: #0f1634;
                        }
                    }
                }
            }

            &.fixedTemplate {
                &.e-treeview .e-ul .e-list-item {
                    & .e-text-content {
                        & .e-list-icon {
                            margin-left: 0;
                        }

                        & .e-list-text {
                            padding-left: 0;
                            margin-left: 1.5rem !important;
                            display: flex;
                            align-items: center;
                            min-height: 30px;
                            height: 48px;
                            line-height: 30px;
                            width: 100%;

                            & .treeview-Icon {
                                line-height: normal;
                                width: 1.5rem;

                                &.treeview-Icon-md {
                                    width: 2rem;
                                }

                                & i {
                                    font-size: 0.8125rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__listView.e-listview {
            // padding: 0.5rem;

            & .e-list-parent .e-list-item {
                width: auto !important;
                height: auto !important;
                min-height: 3rem !important;
                justify-content: flex-start !important;
                padding: 0 !important;
                margin: 0 !important;
                border-radius: 4px;
                border-left: 3px solid transparent;

                &>div {
                    width: 100%;
                }

                & .raf-text-content {
                    min-height: 3rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 1rem;
                    padding-right: 0.5rem;
                }

                & .e-text-content {
                    display: flex;
                    align-items: center;

                    & .e-list-text {
                        font-size: 0.875rem;
                    }
                }

                &.e-hover .e-list-text {
                    color: #fff;
                }

                &.e-active {
                    border-left-color: #58bff6;
                }
            }
        }

        &__btnOutter {
            padding: 0.5rem;
            width: 100%;
            position: relative;
        }

        &__actionBtn.e-btn {
            background-color: transparent;
            border-color: transparent;

            & .e-btn-icon {
                color: #fff;
            }
        }

        &__btn.e-btn {
            background-color: transparent;
            color: #fff;
            border: 1px solid !important;
            width: 100%;
            border-radius: 4px;

            &:hover,
            &:active {
                background-color: #0f1634 !important;
            }
        }

        &__items {
            width: 100%;
            padding: 0.5rem;
        }

        &__item {
            width: 100%;
            padding: 0.75rem;
            font-size: 0.875rem;
            font-weight: 400;
            color: #fff;
            border-radius: 4px;

            &:hover {
                background-color: #0f1634;
            }
        }
    }

    &__workspace {
        &__div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__icon {
            width: 1.5rem;
            height: 1.3125rem;
            min-width: 1.5rem;
            min-height: 1.3125rem;
            text-align: center;
        }

        &__text {
            margin-left: 0.5rem;
        }

        & a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: inherit;
            text-decoration: none;
        }
    }

    &__moreMenu {
        background-color: #fff;
        border-radius: 8px;
        filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
        opacity: 1;
        position: fixed;
        min-width: 250px;
        min-height: 100px;
        z-index: 2000;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__secondary__text,
        &__primary__text {
            font-size: 0.75rem;
            color: rgba(255, 255, 255, 0.7);
            position: relative;
            top: 2px;
        }

        &__secondary__text {
            cursor: pointer;
            order: 1;
            text-transform: uppercase;

            & i {
                font-size: inherit;
            }
        }

        &__primary__text {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: normal;
            position: relative;
            bottom: 2px;
            top: unset;
            display: flex;
            align-items: baseline;
            justify-content: center;
            padding-top: 2px;

        }
    }

    &__menu,
    &__moremenu {
        background: #0f1634;
        display: flex;
        flex-direction: column;

        &__list {
            & .e-list-parent.e-ul {
                margin: 0;
                padding: 0;
            }

            & .e-list-item {
                height: unset !important;
                padding: 0 1.25rem !important;

                & .e-sidebar__thumbtack {
                    display: none;
                    color: #fff;
                }

                &.e-hover {
                    background-color: #0f1634;

                    & .e-sidebar__thumbtack {
                        display: block;
                    }
                }
            }

            & a {
                text-decoration: none;
            }

            & .menu-item-text {
                display: flex;
                align-items: center;
                height: 46px;
            }

            & .e-sidebar__list-item-text {
                font-size: 0.875rem;
                padding: 0 0.5rem;
            }

        }
    }

    &__menu {
        background: #142656;
    }

    & .sideNavbar__treeview.e-treeview {
        // height: 100%;

        & .e-list-item {

            & .e-text-content {
                min-height: 3rem;
                display: flex;
                align-items: center;

                & .e-icon-collapsible,
                .e-icon-expandable {
                    color: #fff;

                }

                & .e-list-text {
                    color: #fff;
                    padding: 0 0.25rem;

                    & .menu-item-text {
                        padding: 0.375rem 0;

                        & .menu-item-text-content {
                            white-space: normal;
                            word-break: break-all;
                        }
                    }
                }
            }

            & .e-list-parent.e-ul {
                padding-left: 2rem;
            }

            &>.e-fullrow {
                min-height: 3rem;
            }

            &.e-active {
                &>.e-fullrow {
                    background: rgba(0, 0, 0, 0.3) !important;
                }

                &>.e-text-content .e-icon-collapsible,
                &>.e-text-content .e-icon-expandable {
                    color: #fff;
                }
            }

            &.e-hover {
                &>.e-fullrow {
                    background: #0f1634 !important;
                }

                &>.e-text-content .e-icon-collapsible,
                &>.e-text-content .e-icon-expandable {
                    color: #fff;
                }
            }

            &.e-node-focus[aria-expanded="true"] {
                background: #0f1634 !important;
            }

            &[aria-level="1"][aria-expanded="true"] {
                background: #0f1634 !important;
                // border-left: 4px solid rgb(4, 169, 244) !important;
            }

            // &.e-node-focus[aria-level="1"][aria-expanded="true"] {
            //     border-left: 4px solid rgb(4, 169, 244) !important;
            // }

            & .e-list-item.e-node-focus {
                background-color: transparent !important;
            }
        }
    }

    &.e-left.e-close.e-dock {
        & .sideNavbar__treeview.e-treeview .e-list-item .e-text-content .e-list-text .menu-item-text .menu-item-text-content {
            display: none !important;
        }

    }

    &__profileCardDiv {
        padding: 0 0.5rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & .ddbtnUserProfileCard {
            position: relative;
            overflow: visible !important;
            margin-top: 1rem;

            &::after {
                content: "";
                border-top: 1px solid #004d8e;
                position: absolute;
                top: -0.5rem;
                height: 1px;
                width: 100%;
            }
        }
    }

    &__dialog {
        &.e-dlg-container.leftDialog {
            align-items: flex-start;
            justify-content: flex-start;
        }

        &.rightDialog.e-dialog {
            width: 320px;
            max-height: unset !important;
            height: 100%;
        }

        &.rightDialog.full-height.createEditForm.viewFilterDialog.e-dialog {
            width: 320px !important;
            // background-color: #142656;

            // & .e-dlg-header-content {
            //     background-color: transparent;

            //     & .e-dlg-header {
            //         color: #fff;
            //     }
            // }

            // & .e-dlg-content {
            //     background-color: transparent;
            // }
        }

    }

    &__list-item-text {
        font-size: 0.625rem;
        line-height: normal;
        color: #fff;
        padding: 0.25rem 0 0rem 0;
        // word-break: break-word;
        text-align: center;
        white-space: normal;
    }

    // &.e-open .e-sidebar__right.e-close {
    //     transform: translateX(0%) !important;
    //     visibility: visible !important;
    // }

}

.listSidebarListContainer {
    height: 100%;
    overflow: auto;
}

@media only screen and (max-width: 767px) {
    // .e-sidebar.listSideBar-lg {
    //     width: 90% !important;
    //     min-width: 90% !important;
    // }

    // .e-sidebar.e-sidebar__right {
    //     width: 100% !important;
    //     min-width: 100% !important;
    // }
}

@media only screen and (min-width: 1200px) {
    .e-sidebar__right__chevrons__right__div {
        right: -0.625rem;
    }
}

//treeviewcomponet sytle end