.e-dropdown-popup.e-popup-open.ddbtnUserProfileCard {
    -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .25));
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .25));
    margin-top: 0.5rem;
    max-height: none;
    opacity: 1;
    overflow: visible;
}

.profile-card {
    background-color: #fff;
    border: 0 !important;
    color: #666;
    height: auto;
    max-height: calc(90vh - 60px);
    overflow: auto;
    overflow-x: hidden;
    padding: 0 !important;
    width: 250px;
}

.e-card-header-title {
    color: #202124;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: .29px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.e-bigger .e-btn.dropdown-menu-button,
.e-btn.dropdown-menu-button {
    background-color: transparent;
    border-color: transparent;
    font-size: 14px;
    font-weight: 400;
    margin: 0 !important;
    overflow: hidden;
    padding: 10px 3rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.profile-card button.e-btn.dropdown-menu-button:hover {
    border-color: #f7f8f8;
}

.profile-card button.e-btn:hover {
    background: #f7f8f8;
}

button.e-btn.raf_profile_card {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    padding: 0 !important;
    width: 100%;
    text-align: left;

    & .border-avatar {
        border: 1px solid #fff !important;

        & .fa {
            color: #fff;
        }
    }

    & .e-caret {
        color: #fff;
    }
}

button.e-btn.raf-ProfileCardDDB.raf_profile_card {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2); // Variable color with 20% opacity
    border-color: transparent;
    padding: 0.5rem 0.75rem !important;
    width: 100%;
    text-align: left;
    line-height: normal !important;
    min-height: unset !important;
    max-height: unset !important;
    height: auto !important;
    border-radius: 0.5rem;

    & .border-avatar {
        border-color: #fff !important;

        & .fa {
            color: #fff;
        }
    }

    & .e-caret {
        color: #fff;
    }
}

.raf_profile_card.e-dropdown-popup:not(.e-popup-open) {
    display: none;
}