.e-sidebar__treeview {
    background-color: #0071B2 !important;

    &__logo {
        width: 200px;
        max-width: 200px;
        height: auto;
        margin-top: 0;
        margin: 0 1rem;
    }

    &__header {
        // border-bottom: 1px solid #4b86b4;
        padding-bottom: 0.5rem;
    }

    & .e-list-item {

        &.e-hover,
        &.e-active.e-hover,
        &.e-active.e-node-focus,
        &.e-active {
            &>.e-fullrow {
                background-color: #064b76 !important;
                border-color: #064b76 !important;
            }
        }

        &.e-active {
            &>.e-fullrow {
                border-left-color: #fff !important;
            }
        }
    }

    .e-text-content .e-list-icon {}
}

.sidemenu-toggle-btn.backArrowIcon.backArrowIcon-md {
    color: var(--content-neutral-white);
    background-color: #0071B2;
}