.brandLogo__img__outter {
    height: 32px;
    display: flex;
    align-items: center;
}

.brandLogoDiv {
    // height: 60px;
    // border-bottom: 1px solid #4b86b4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    // min-height: 60px;
}

.prodLogoImage {
    max-width: 170px;
    height: 75%;
}

.prodLogoImage_div {
    width: 70%;
    height: 70%;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .prodLogoImage {
        height: 75%;
    }
}

.profileDiv {
    align-items: center;
    border-bottom: 1px solid #4b86b4;
    border-left: 0;
    border-right: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 0;
    min-height: 40px;
    padding: 0 0.75rem;
    font-size: 1rem;
    font-weight: 400;
}

.e-sidebar__moremenu {
    background-color: #035b91;

    &__list .e-list-item.e-hover {
        background-color: #014d7b;
        border-color: #014d7b;
    }
}

.e-sidebar__menu {
    background-color: #0071B2;

    &__list .e-list-item.e-hover {
        background-color: #014d7b;
        border-color: #014d7b;
    }
}

.sidebar-nav__workEsioLogo {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding-bottom: 1.25rem;

    &__text {
        font: var(--body2-regular);
        color: var(--content-neutral-white);
        padding-right: 0.5rem;
    }

    &__img {
        // width: 100px;
        height: 24px;
    }
}

@media only screen and (min-width: 576px) {
    .sidebar-nav__workEsioLogo {
        padding-bottom: 0.5rem;
    }
}